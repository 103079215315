import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { SolutionLayout } from "../components/solutions-layout"

export default function RealEstateManagement() {
  return (
    <Layout tcoreinsight={false}>
      <SEO />
      <SolutionLayout
        data={{
          slug: "real-estate-pro",
          hero: {
            image: require("./../assets/Realestatepro-screen.png"),
            titleSecond: "RealEstatePro",
            subtitle: "Cloud based Real estate management system",
            content:
              "RealEstatePro is real estate management system that manages the overall operational activities and processes, starting from the management of the property, to the management of real estate agencies, agents, clients and financial transactions.",
          },
          section: {
            title: "How can RealEstatePro help you",
            subtitle: (
              <>
                RealEstatePro does the weight-lifting for you while you
                concentrate on running the business.
                <br className="hidden md:block" /> Automate the way you manage
                your customers, properties and sales using our solution.
                Schedule viewing of properties, sales and payments using
                RealestatePro.
              </>
            ),
          },
          imageSection1: {
            image: require("./../assets/realestatepro-screen2.png"),
            title: "Easily manage business",
            overlap: true,
            content:
              "Add new sites, properties and customers easily.Migrate your existing customers and properties to the system seamlessly.",
          },
          imageSection2: {
            image: require("./../assets/realestatepro-screen-3.png"),
            title: "Manage your sales from a simple to use dashboard",
            content:
              "Manage all your sales processes, deals and customers from a simple easy to use dashboard.",
          },
        }}
      />
    </Layout>
  )
}
